import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const WorkFilter = () => {
  const { t } = useTranslation();
  //const slug = props.location.pathname.split('/').pop()
  const [expanded, setExpanded] = useState(false);
  let pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  const slug = pathname.split('/').pop();
  const options = [
    {
      to: '/showreel/2022',
      label: 'showreelNav2022',
    },
    {
      to: '/showreel/2021',
      label: 'showreelNav2021',
    },

  ];

  const ButtonAll = () => {
    return (
      <button onClick={() => setExpanded(true)} className="relative pr-3">
        <svg
          className="absolute right-0 top-1.5"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.00016 7.63883L1.66683 2.22217L8.3335 2.22217L5.00016 7.63883Z" fill="black" />
        </svg>
        {t('showreelNavLatest')}
      </button>
    );
  };

  const Button = options.map((item) => {
    if (slug === item.to.split('/').pop()) {
      return (
        <button key={item.to} onClick={() => setExpanded(true)} className="relative pr-3">
          <svg
            className="absolute right-0 top-1.5"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.00016 7.63883L1.66683 2.22217L8.3335 2.22217L5.00016 7.63883Z"
              fill="black"
            />
          </svg>
          {t(item.label)}
        </button>
      );
    }
  });

  const handleClose = () => {
    setTimeout(() => {
      setExpanded(false);
    }, 1000);
  };

  const Filters = options.map((item) => {
    return (
      <span key={item.to} className="lg:mr-4 py-0.5 block lg:inline-block">
        <Link
          to={item.to}
          onClick={() => handleClose}
          className={` ${
            slug === item.to.split('/').pop() ? 'active-work' : null
          } transition-opacity ease-in-out hover:opacity-50 inline-block overflow-hidden`}
        >
          {t(item.label)}
        </Link>
      </span>
    );
  });

  return (
    <div className="relative z-10 text-sm lg:text-lg">
      <div className="flex visible lg:hidden pb-8">
        {pathname === '/showreel/' || pathname === '/showreel' ? ButtonAll() : Button}
      </div>
      <nav
        className={`absolute bg-white p-4 -left-4 top-[-28px] pt-6 flex-col lg:px-0 lg:static lg:pt-5 lg:pb-10 ${
          expanded ? 'visible' : 'invisible'
        } overflow-scroll lg:!visible flex lg:flex-row`}
      >
        <span className="lg:mr-4 py-0.5 block lg:inline-block">
          <Link
            to="/showreel"
            onClick={() => handleClose}
            className={` ${
              pathname === '/showreel/' || pathname === '/showreel' ? 'active-work' : null
            } transition-opacity ease-in-out hover:opacity-50 inline-block overflow-hidden`}
          >
            {t('showreelNavLatest')}
          </Link>
        </span>
        {Filters}
      </nav>
    </div>
  );
};

export default WorkFilter;
